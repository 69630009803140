<template>
  <DataPermsByCostCtr :ref="tabRef"/>
</template>

<script>
import { defineComponent, onActivated, onMounted, ref } from "vue";
import DataPermsByCostCtr from "./DataPermsByCostCtr/index";
// import DataPermsByPerson from "./DataPermsByPerson/index";
import { useGlobalPropertyHook } from "../../../../hooks/common";

export default defineComponent({
  name: "maintainDataPerms",
  components: {
    DataPermsByCostCtr,
    // DataPermsByPerson,
  },
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();
    const activeTab = ref("DataPermsByCostCtr");
    const tabRef = ref();

    const tabChange = () => {
      $EventBus.emit("refreshDataPerms");
      getEMOptions();
      getCtrOptions();
    };

    const tabList = ref([
      {
        name: "DataPermsByCostCtr",
        title: "人员权限管理",
      },
      {
        name: "DataPermsByPerson",
        title: "用户管理",
      },
    ]);

    const getEMOptions = () => {
      $api.findAllStaffs().then((res) => {
        const allStaffs = res.data;
        $EventBus.emit("getAllStaffs", allStaffs);
      });
    };

    const getCtrOptions = () => {
      $api.getAllCostCtrList().then((res) => {
        const ctrOps = res.data;
        $EventBus.emit("getCtrOps", ctrOps);
      });
    };

    onMounted(() => {
      getEMOptions();
      getCtrOptions();
    });

    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      tabList,
      tabRef,
      getEMOptions,
      getCtrOptions,
      activeTab,
      tabChange,
    };
  },
});
</script>
